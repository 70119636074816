import React from 'react';
import {mainFirebase} from './../../firebase-config';

class FeaturedTab extends React.Component{
    constructor(props){
        super(props);
        this.randomRecipe = '';
        this.state = {
            myDailyRecipe: '',
            imgUrl: ''
        }
    }
    componentDidMount(){
        if(this.getCookie('recipeOfTheDay') === ""){
            const db = mainFirebase.firestore();
            db.collection('recipes').get().then(data => {
                this.randomRecipe =  data.docs[Math.floor(Math.random() * data.docs.length)];
                this.createCookie('recipeOfTheDay', this.randomRecipe.id);
                this.getSpecificDocument(this.getCookie('recipeOfTheDay'));
            })
        } else {
            this.getSpecificDocument(this.getCookie('recipeOfTheDay'));
        }
    }

    createCookie = (name, value, path) => {
        let expires = "";
        const date = new Date();
        const midnight = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
        expires = "; expires=" + midnight.toGMTString();
        if (!path) {
            path = "/";
        }
        document.cookie = name + "=" + value + expires + "; path=" + path;
    }

    getSpecificDocument = (docId) => {
        const db = mainFirebase.firestore();
        db.collection('recipes').doc(docId).get().then(data => {
            if (data.exists) {
                this.setState({myDailyRecipe: data.data()});
                this.getImgUrl(data.data().image_filename);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        })
    }

    getCookie = (cname) => {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    getImgUrl = filename => {
        if(filename){
            const storageRef = mainFirebase.storage().ref();
            storageRef.child(`recipe_image/${filename}`).getDownloadURL().then(url => {
                this.setState({imgUrl: url});
            })
        }
    }

    render(){
        const localDate = new Date().toLocaleDateString();
        let rtype = 'Breakfast';
        if(this.state.myDailyRecipe.rtype === '2'){
            rtype = 'Lunch';
        } else if (this.state.myDailyRecipe.rtype === '3'){
            rtype = 'Dinner'
        } else if (this.state.myDailyRecipe.rtype === '4') {
            rtype = 'Snacks'
        }
        return(
            <div className="featured">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <h4>{localDate}</h4>
                            <h3>Recipe of the day</h3>
                        </div>
                        <div className="col-lg-8">
                            <div className="box grid recipes">
                                <div className="by"><i className="fa fa-cutlery" aria-hidden="true"></i> {rtype}</div>
                                <a href={"/recipe-detail/" + this.getCookie('recipeOfTheDay')}><img src={this.state.imgUrl} alt="" /></a>
                                <h2><a href={"/recipe-detail/" + this.getCookie('recipeOfTheDay')}>{this.state.myDailyRecipe.title}</a></h2>
                                <p>{this.state.myDailyRecipe.description}</p>
                                {this.state.myDailyRecipe.ingredients !== undefined ? <div className="tag">
                                    {this.state.myDailyRecipe.ingredients[0] ? <a href="#">{this.state.myDailyRecipe.ingredients[0]}</a> : ''}
                                    {this.state.myDailyRecipe.ingredients[1] ?  <a href="#">{this.state.myDailyRecipe.ingredients[1]}</a> : ''}
                                    {this.state.myDailyRecipe.ingredients[2] ? <a href="#">{this.state.myDailyRecipe.ingredients[2]}</a> : ''}
                                </div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeaturedTab;