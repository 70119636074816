import firebase from 'firebase';

const mainFirebaseConfig = {
    apiKey: "AIzaSyBYBhZ024M0ka7XRKRf5CinrJuBbf8rlYg",
    authDomain: "healthy-living-by-design.firebaseapp.com",
    databaseURL: "https://healthy-living-by-design.firebaseio.com",
    projectId: "healthy-living-by-design",
    storageBucket: "healthy-living-by-design.appspot.com",
    messagingSenderId: "1026587412627"
}



export const mainFirebase = firebase.initializeApp(mainFirebaseConfig);