import React from 'react';
import {mainFirebase} from './../../firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import {MegadraftEditor, editorStateFromRaw} from "megadraft";

class ProductInformation extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data: [],
            imgUrl: '',
            description: editorStateFromRaw(null)
        }
    }

    componentDidMount(){
        const db = mainFirebase.firestore();
        const docRef = db.collection('products').doc(this.props.match.params.id);
        docRef.get().then((doc) => {
            if (doc.exists) {
                this.setState({data: doc.data(), description: editorStateFromRaw(JSON.parse(doc.data().description))});
                this.getImgUrl(doc.data().image_filename);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    getImgUrl = filename => {
        if(filename){
            const storageRef = mainFirebase.storage().ref();
            storageRef.child(`product_image/${filename}`).getDownloadURL().then(url => {
                this.setState({imgUrl: url});
            })
        }
    }


    render(){
        return(
            <React.Fragment>
                <div className="recipe-detail">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <h1>{this.state.data.title}</h1>
                        </div>
                        <div className="col-lg-8" style={{'textAlign': 'center'}}>
                            <img src={this.state.imgUrl} style={{'maxWidth': '500px', 'maxHeight': '500px', 'display': 'inline-block'}} alt="" />
                            <MegadraftEditor readOnly={true} editorState={this.state.description} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <a className="btn btn-primary" style={{'color':'white'}} onClick={() => {window.history.back()}}><span>Go Back</span></a>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

export default ProductInformation;