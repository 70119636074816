import React from 'react';
import queryString from 'query-string'
class BlogDetail extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            title: ''
        }
    }
    
    componentDidMount(){
        const values = queryString.parse(this.props.location.search)
        this.setState({title: values.title});
        // document.getElementById('htmlHandlerContainer').style.height = document.documentElement.clientHeight + 'px';
    }

    loadIframe = (e) => {
        const iFrameID = document.getElementById('htmlHandler');
        if(iFrameID) {
            // here you can make the height, I delete it first, then I make it again
            iFrameID.height = "";
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
      }  
    }

    render(){
        return(
            <React.Fragment>
                <div className="recipe-detail">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 text-center" id='htmlHandlerContainer'>
                                <iframe onLoad={this.loadIframe} type="text/html" id='htmlHandler' className='htmlHandler' src={`/blogs/${this.state.title}/index.html`}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default BlogDetail;