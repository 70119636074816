import React from 'react';
import {mainFirebase} from './../../firebase-config';
import "./style.scss";
class TopRecipeBar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            breakfast: ['', ''],
            lunch: ['', ''],
            dinner: ['', ''],
            snacks: ['', ''],
            b_imgUrl: '',
            l_imgUrl: '',
            d_imgUrl: ''
        }
        
    }
    componentDidMount(){
        const db = mainFirebase.firestore();
        const colRef = db.collection('recipes');

        colRef.where('rtype', '==', '1').where('isTop', '==', true).get().then(bf => {
            bf.forEach(d => {
                this.setState({breakfast: [d.id, d.data()]});
                this.getImageUrl(1, d.data().image_filename);
            })
        })
        colRef.where('rtype', '==', '2').where('isTop', '==', true).get().then(bf => {
            bf.forEach(d => {
                this.setState({lunch: [d.id, d.data()]});
                this.getImageUrl(2, d.data().image_filename);
            })
        })
        colRef.where('rtype', '==', '3').where('isTop', '==', true).get().then(bf => {
            bf.forEach(d => {
                this.setState({dinner: [d.id, d.data()]});
                this.getImageUrl(3, d.data().image_filename);
            })
        })
    }
    getImageUrl = (q, fn) => {
        if(fn){
            const storageRef = mainFirebase.storage().ref();
            storageRef.child(`recipe_image/${fn}`).getDownloadURL().then(url => {
                if(q === 1){
                    this.setState({b_imgUrl: url});
                } else if (q === 2){
                    this.setState({l_imgUrl: url});
                } else if (q === 3){
                    this.setState({d_imgUrl: url});
                }
            })
        }
    }
    render(){
        return (
            <div className="bg-background-color">
                <div className="container mypaddingtop">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 animate-top">
                            <div className="card__tag-wrapper card__tag-wrapper-main">
                                <span className="card__triangle"></span>
                                    <p className="card__tag">
                                        Top Breakfast Recipe
                                    </p> 
                                <span className="card__triangle--flipped"></span>
                            </div>
                            <img src={this.state.b_imgUrl} alt="" className='main-image' />
                            <div className='main-title'>
                                <div className='main-title-sub'>
                                    <a href={"/recipe-detail/" + this.state.breakfast[0]}>{this.state.breakfast[1].title}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 animate-top">
                            <div className='side-card'>
                                <div className="card__tag-wrapper">
                                    <span className="card__triangle"></span>
                                        <p className="card__tag">
                                            Top Lunch Recipe
                                        </p> 
                                    <span className="card__triangle--flipped"></span>
                                </div>
                                <img src={this.state.l_imgUrl} alt="" className='side-image' />
                                <div className='side-card-container'>
                                    <h4><a href={"/recipe-detail/" + this.state.lunch[0]}>{this.state.lunch[1].title}</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 animate-top">
                            <div className='side-card'>
                                <div className="card__tag-wrapper">
                                    <span className="card__triangle"></span>
                                        <p className="card__tag">
                                            Top Dinner Recipe
                                        </p> 
                                    <span className="card__triangle--flipped"></span>
                                </div>
                                <img src={this.state.d_imgUrl} alt="" className='side-image' />
                                <div className='side-card-container'>
                                    <h4><a href={"/recipe-detail/" + this.state.dinner[0]}>{this.state.dinner[1].title}</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                

                <div className='row'>
                    <div className='col-lg-6 '>
                        <div className='row explore-container'>
                            <h4 className='explore-container-title'>Explore</h4>
                        </div>
                        <div className='explore-container-sub'>
                            <div className='animate-top'>
                                <a className='mini-icons' href="/recipe">
                                    <div className='side-icons recipe-colors-fa'><i className='fa fa-clipboard-list'></i></div>
                                    <div className="card__tag-wrapper card-pos-relative">
                                        <span className="card__triangle" id='card__triangle-recipe'></span>
                                            <p className="card__tag" id='card__tag-recipe'>
                                                Recipes
                                            </p> 
                                        <span className="card__triangle--flipped" id='card__triangle--flipped-recipe'></span>
                                    </div>
                                </a>
                            </div>
                            <div className='ml-5 animate-top'>
                                <a className='mini-icons' href="/quick-tips">
                                <div className='side-icons cocktails-colors-fa'><i className='fa fa-bolt'></i></div>
                                <div className="card__tag-wrapper card-pos-relative">
                                    <span className="card__triangle" id='card__triangle-cocktail'></span>
                                        <p className="card__tag" id='card__tag-cocktail'>
                                            Quick Tips
                                        </p> 
                                    <span className="card__triangle--flipped" id='card__triangle--flipped-cocktail'></span>
                                </div>
                                </a>
                            </div>
                            <div className='ml-5 animate-top'>
                                <a className='mini-icons' href="/blogs?category=0&limit=6">
                                <div className='side-icons by-region-colors-fa'><i className='fa fa-blog'></i></div>
                                <div className="card__tag-wrapper card-pos-relative">
                                    <span className="card__triangle" id='card__triangle-region'></span>
                                        <p className="card__tag" id='card__tag-region'>
                                            Blogs
                                        </p> 
                                    <span className="card__triangle--flipped" id='card__triangle--flipped-region'></span>
                                </div>
                                </a>
                            </div>
                            <div className='ml-5 animate-top'>
                                <div className='side-icons ingredients-colors-fa'><i className='fa fa-cookie-bite'></i></div>
                                <div className="card__tag-wrapper card-pos-relative">
                                    <span className="card__triangle" id='card__triangle-in'></span>
                                        <p className="card__tag" id='card__tag-in'>
                                            Ingredients
                                        </p> 
                                    <span className="card__triangle--flipped" id='card__triangle--flipped-in'></span>
                                </div>
                            </div>
                        </div>  
                        <div className='explore-container-sub'>
                            <div className='animate-top'>
                                <div className='side-icons occasions-colors-fa'><i className='fa fa-birthday-cake'></i></div>
                                <div className="card__tag-wrapper card-pos-relative">
                                    <span className="card__triangle" id='card__triangle-oc'></span>
                                        <p className="card__tag" id='card__tag-oc'>
                                            Occasions
                                        </p> 
                                    <span className="card__triangle--flipped" id='card__triangle--flipped-oc'></span>
                                </div>
                            </div>

                            <div className='ml-5 animate-top'>
                                <a className='mini-icons' href="/profile">
                                    <div className='side-icons profile-colors-fa'><i className='fa fa-user-circle'></i></div>
                                    <div className="card__tag-wrapper card-pos-relative">
                                        <span className="card__triangle" id='card__triangle-pr'></span>
                                            <p className="card__tag" id='card__tag-pr'>
                                                Profile
                                            </p> 
                                        <span className="card__triangle--flipped" id='card__triangle--flipped-pr'></span>
                                    </div>
                                </a>
                            </div>
                            <div className='ml-5 animate-top'>
                                <a className='mini-icons' href="/store">
                                    <div className='side-icons store-colors-fa'><i className='fa fa-store'></i></div>
                                    <div className="card__tag-wrapper card-pos-relative">
                                        <span className="card__triangle" id='card__triangle-st'></span>
                                            <p className="card__tag" id='card__tag-st'>
                                                Store
                                            </p> 
                                        <span className="card__triangle--flipped" id='card__triangle--flipped-st'></span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-6 '>
                        <div className='row explore-container'>
                            <h4 className='explore-container-title'>Fitness Blueprint</h4>
                        </div>
                        <div className='row blog-container'>
                            <div className='col-lg-12'>
                                <div className='main-image image-fitness' />
                                <div className='main-title'>
                                    <div className='main-title-sub'>
                                        <a href='#'>Visit Website >>></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default TopRecipeBar;