import React from 'react';
import {mainFirebase} from '../../firebase-config'; 
import "./../top-recipe-bar/style.scss";

class ListCards extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            uiHandler: []
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.querySnapshot !== this.props.querySnapshot){
            if(this.props.querySnapshot.length === 0){
                this.setState({uiHandler: []})
            } else {
                this.getPropsData();
            }
        }
    }

    getImage = async filename => {
        if(filename){
            const storageRef = mainFirebase.storage().ref();
            return await storageRef.child(`blogs_image/${filename}`).getDownloadURL()
        }
    }

    loadMore = e => {
        e.preventDefault();
        this.props.loadMore();
    }


    getPropsData = () => {
        this.props.querySnapshot.forEach(async (doc) => {
            const uiHandler = []
            const imgURL = await this.getImage(doc.data().image_filename);
            uiHandler.push((
                <div className="col-lg-4 col-sm-6" key={doc.id} style={{'marginTop': '30px'}}>
                    <div className='side-card'>
                        <img src={imgURL} alt="" className='side-image' />
                        <div className='side-card-container'>
                            <h4><a href={"/blog-detail/" + doc.id + '?title=' + doc.data().title}>{doc.data().title}</a></h4>
                        </div>
                    </div>
                </div>
            ))
            this.setState({uiHandler: [...this.state.uiHandler, uiHandler]})
        });
    }


    render(){
        return(
           <React.Fragment>
                { this.state.uiHandler }
                <div className="col-lg-12 text-center" style={{'marginTop': '30px'}}>
                    {
                        this.props.loadSpinner ? 
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : 
                            this.props.hideLoadMoreIcon ?
                            '' :  this.state.uiHandler.length === 0 ? '' : <a href="#" className="btn btn-load" onClick={this.loadMore}>Load More</a>
                    }
                </div>
            </React.Fragment>
                    
        )
    }
}

export default ListCards;