import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.scss';

import NavigationBar from './components/navigation-bar';
import FamilyNavigation from './components/navigation-bar/family-nav';
import HomePage from './pages/Home';
import RecipePage from './pages/Recipe';
import RecipeDetail from './pages/RecipeDetail';
import Footer from './components/footer';

import Profile from './pages/Profile';
import Store from './pages/Store';
import ProductInformation from './pages/Store/ProductInfo';
import MyDownloads from './pages/Downloads';
import QuickTips from './pages/QuickTips';
import BlogDetail from './pages/BlogDetail';
import BlogsPage from './pages/Blogs';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'

library.add(faStroopwafel)


class App extends Component {

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  
  handleScroll = () => {
    const fixedHeader = document.getElementById('fixed-header');

    if(window.scrollY > 425){
      fixedHeader.style.transform = 'translateY(-40px)';
    } else {
      fixedHeader.style.transform = 'translateY(0px)';
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div className='fixed-header' id='fixed-header'>
          <FamilyNavigation />
          <NavigationBar />
        </div>
        <div className='pt-190'>
          <Switch >
              <Route exact path='/' component={HomePage} />
              <Route exact path='/recipe' component={RecipePage} />
              <Route exact path='/quick-tips' component={QuickTips} />
              <Route exact path='/recipe-detail/:id' component={RecipeDetail} />
              <Route exact path='/profile' component={Profile} />
              <Route exact path='/store' component={Store} />
              <Route exact path='/product-information/:id' component={ProductInformation} />
              <Route exact path='/downloads' component={MyDownloads} />
              <Route exact path='/blogs' component={BlogsPage} />
              <Route exact path='/blog-detail/:id' component={BlogDetail} />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
