import React from 'react';
import './store.css';
import {mainFirebase} from './../../firebase-config';
import ItemList from './ItemList';
import LoadingOverlay from 'react-loading-overlay';
class Store extends React.Component{
    constructor(props){
        super(props);
        this.firestore = mainFirebase.firestore();
        this.state = {
            products: [],
            loadSpinner: false,
            isSignedIn: false,
            hasRegisteredCard: false,
            user: '',
            cardId: '',
            isLoading: false,
            isLoadingText: ''
        }
    }

    componentWillMount(){
        this.unregisterAuthObserver = mainFirebase.auth().onAuthStateChanged(
            (user) => {
                this.setState({isSignedIn: !!user})
                if(this.state.isSignedIn){
                    this.firestore.collection('stripe_customers').doc(`${user.uid}`).collection('sources').get().then(data => {
                        if(!data.empty){
                            data.forEach(doc => {
                                const id = doc.data().id
                                this.setState({hasRegisteredCard: true, user: user, cardId: id});
                            })
                            
                        } else {
                            this.setState({hasRegisteredCard: false, user: user});
                        }
                    })
                }
            }
        );
    }

    componentDidMount(){
        this.setState({loadSpinner: true});
        this.firestore.collection("products").get().then((querySnapshot) => {
            this.setState({products: querySnapshot});
        });
    }

    stopLoading = () => {
        this.setState({loadSpinner: false});
    }

    setLoadingOverlay = (isShown, textShown) => {
        this.setState({isLoading: isShown, isLoadingText: textShown});
    }

    render(){
        return(
            <React.Fragment>
                <LoadingOverlay active={this.state.isLoading} spinner text={this.state.isLoadingText}>
                <div className='main-container limit-height'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 page-sidebar mobile-filter-sidebar'>
                                <aside>
                                    <div className='sidebar-modern-inner'>
                                        <div className="block-title has-arrow sidebar-header">
                                            <h5><a href="#" onClick={e => e.preventDefault()}>All Categories</a></h5>
                                        </div>
                                        <div className='block-content categories-list  list-filter'>
                                            <ul className='list-unstyled'>
                                            <li>
                                                <a href="#" onClick={e => e.preventDefault()}>
                                                    <span className="title">E-Books</span>
                                                </a>
                                            </li>   
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>

                            <div className='col-md-9 page-content col-thin-left'>
                                <div className='category-list make-grid'>
                                    <div className='adds-wrapper row no-margin'>
                                        <ItemList products={this.state.products} cardId={this.state.cardId} uid={this.state.user.uid} hasRegisteredCard={this.state.hasRegisteredCard} isSignedIn={this.state.isSignedIn} isLoading={this.state.loadSpinner} stopLoading={this.stopLoading} setLoadingOverlay={this.setLoadingOverlay}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </LoadingOverlay>
            </React.Fragment>
        )
    }
}

export default Store;