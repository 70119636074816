import React from 'react';
import {mainFirebase} from './../../firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import './../Store/store.css';
import LoadingOverlay from 'react-loading-overlay';

class MyDownloads extends React.Component{
    constructor(props){
        super(props);
        this.firestore = mainFirebase.firestore();
        this.purchasedProducts = [];
        this.infoPurchasedProducts = [];
        this.state = {
            uiHandler: [],
            isLoading: false,
            isLoadingText: ''
        }
    }

    componentDidMount(){
        this.firestore.collection("products").get().then((querySnapshot) => {
            querySnapshot.forEach(doc => {
                if(this.purchasedProducts.includes(doc.id)){
                    this.infoPurchasedProducts.push(doc);
                }
            })
        });
        this.getData();
    }

    downloadProduct = async filename => {
        if(filename){
            const storageRef = mainFirebase.storage().ref();
            const url =  await storageRef.child(`product_zip/${filename}`).getDownloadURL();
            window.open(url)
        }
    }

    isUserLoggedIn = () => {
        return new Promise((resolve, reject) => {
            mainFirebase.auth().onAuthStateChanged( user => {
                const isSignedIn = !!user;
                if(isSignedIn){
                    resolve({isSignedIn: true, uid: user.uid})
                } else {
                    resolve({isSignedIn: false, uid: ''})
                }
            }, err => {
                reject(err);
            })
        })
    }

    getPurchasedProducts = (uid) => {
        return new Promise((resolve, reject) => {
            this.firestore.collection('stripe_customers').doc(`${uid}`).collection('charges').get().then(data => {
                if(!data.empty){
                    const productIds = [];
                    data.forEach(doc => {
                        productIds.push(doc.data().productId);
                    });
                    resolve(productIds);
                } else {
                    resolve([]);
                }
            }, err => {
                reject(err);
            })
        })
    }

    getAllProducts = () => {
        return new Promise((resolve, reject) => {
            this.firestore.collection("products").get().then((querySnapshot) => {
                resolve(querySnapshot)
            }, err => {reject(err)});
        })
    }

    getImage = async filename => {
        if(filename){
            const storageRef = mainFirebase.storage().ref();
            return await storageRef.child(`product_image/${filename}`).getDownloadURL()
        }
    }

    getData = async () => {
        try {
            this.setState({isLoading: true, isLoadingText: 'Getting your purchased items.. Please wait..'});
            const isUserLoggedIn = await this.isUserLoggedIn();
            if(isUserLoggedIn.isSignedIn){
                const purchasedProductIds = await this.getPurchasedProducts(isUserLoggedIn.uid);
                const purchasedProductDetails = [];
                if(purchasedProductIds.length !== 0){
                    const allProducts = await this.getAllProducts();
                    allProducts.forEach(doc => {
                        if(purchasedProductIds.includes(doc.id)){
                            purchasedProductDetails.push(doc);
                        }
                    })

                    if(purchasedProductDetails.length !== 0){
                        purchasedProductDetails.forEach(async (doc) => {
                            const uiHandler = [];
                            const imgURL = await this.getImage(doc.data().image_filename);
                            uiHandler.push((
                                <div className='item-list' key={doc.id}>
                                    <div className='row'>
                                        <div className="col-md-2 no-padding photobox">
                                            <div className="add-image">
                                                <a href={"/product-information/" + doc.id} className='add-image'>
                                                    <img className="thumbnail no-margin" style={{'height': '200px', 'width': '200px'}} src={imgURL} alt="img" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="add-desc-box col-md-7">
                                            <div className="ads-details">
                                                <h5 className="add-title">
                                                    <a href={"/product-information/" + doc.id}>{doc.data().title}</a>
                                                </h5>
                                                <span className="info-row"> 
                                                    {/* <span className="item-location"><i className="fa fa-map-marker-alt"></i> {doc.data().description} </span> */}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center price-box">
                                            <a className="btn btn-primary" style={{'color':'white'}} onClick={e => this.downloadProduct(doc.data().zip_filename)}> <FontAwesomeIcon icon={faDownload} />  <span>Download</span></a>
                                        </div>
                                    </div>
                                </div>
                            ))
                            this.setState({uiHandler: [...this.state.uiHandler, uiHandler]})
                        });   
                    } else {
                        const uiHandler = [];
                        uiHandler.push((
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h5>No available items to download. Go buy to the store.</h5>
                                </div>
                            </div>
                        ))
                        this.setState({uiHandler: [uiHandler]})
                    }
                } else {
                    const uiHandler = [];
                    uiHandler.push((
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h5>No available items to download. Go buy to the store.</h5>
                            </div>
                        </div>
                    ))
                    this.setState({uiHandler: [uiHandler]})
                }
            }
            this.setState({isLoading: false, isLoadingText: ''});
        } catch(e) {

        }
    }

    render(){
        return(
            <React.Fragment>
                <LoadingOverlay active={this.state.isLoading} spinner text={this.state.isLoadingText}>
                    <div className='list'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h5>Your Purchased Items</h5>
                                </div>
                            </div>
                            <div className='row' className='limit-height'>
                                <div className='col-md-12 page-content col-thin-left'>
                                    <div className='category-list make-grid'>
                                        <div className='adds-wrapper row no-margin'>
                                            { this.state.uiHandler }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </React.Fragment>
        )
    }
}

export default MyDownloads;