import React from 'react';
import {withRouter} from 'react-router-dom';
import './nav.scss';
import {mainFirebase} from './../../firebase-config';
import RecipeList from './../list-cards/recipes';

class NavigationBar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            burgerOpen: false,
            searchInput: '',
            uiHandler: [],
            uiHandlerBlog: [],
            hasSearched: false
        }

        const firestore = mainFirebase.firestore();
        this.colRefBlogs = firestore.collection('blogs');
        this.colRefRecipes = firestore.collection('recipes');
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const img = document.getElementById('nav-logo-image');
        const img2 = document.getElementById('nav-logo-image2');
        const bgNavContainer = document.getElementById('bg-mynav-container');
        const socialIcons = document.getElementById('social-icons');
        if(window.scrollY > 5){
            img.style.display = 'none';
            img2.style.display = 'block';
            bgNavContainer.style.height = '70px';
            // socialIcons.style.display = 'flex';

        } else {
            img.style.display = 'block';
            img2.style.display = 'none';
            bgNavContainer.style.height = '99px';
            // socialIcons.style.display = 'none';
        }
    }

    toggleBurger = () => {
        this.setState({
            burgerOpen: !this.state.burgerOpen
        });
        this.checkModalOpen();
    }

    checkModalOpen = () => {
        const burgerModal = document.getElementById('burger-open');
        const burgerIcon = document.getElementById('nav-burger-icon-font').childNodes;
        this.setState({uiHandler: [], uiHandlerBlog: [], searchInput: ''})
        if(this.state.burgerOpen){
            burgerModal.style.display = 'none';
            burgerIcon[0].className = 'fa fa-search';
        } else {
            burgerModal.style.display = 'flex';
            burgerIcon[0].className = 'fas fa-times';
            document.getElementById("searchInput").focus()
        }
    }

    goSearch = e => {
        e.preventDefault();
        this.setState({uiHandler: [], uiHandlerBlog: [], hasSearched: true})
        const searchInput = this.state.searchInput.trim().toUpperCase();
        this.colRefRecipes.get().then((q => {
            
            let uiHandler = [(<div>Recipes Results:</div>)];
            q.forEach(doc => {
                if(doc.data().title.toUpperCase().includes(searchInput)){
                    uiHandler.push((
                        <h4><a href={"/recipe-detail/" + doc.id}>{doc.data().title}</a></h4>
                    ))
                }
            })

            if(uiHandler.length === 1){
                uiHandler = false;
            }

            this.setState({uiHandler: [uiHandler]})
            
        }))

        this.colRefBlogs.get().then((q => {
                let uiHandlerBlog = [(<div>Blogs Results:</div>)];
                q.forEach(doc => {
                    if(doc.data().title.toUpperCase().includes(searchInput)){
                        uiHandlerBlog.push((
                            <div>
                                <h4><a href={"/blog-detail/" + doc.id + '?title=' + doc.data().title}>{doc.data().title}</a></h4>
                            </div>
                        ))
                        
                    }
                })

                if(uiHandlerBlog.length === 1){
                    uiHandlerBlog = false;
                }

                this.setState({uiHandlerBlog: [uiHandlerBlog]})
            
        }))
    }

    inputHandler = e => {
        this.setState({
            [e.target.id]: e.target.value,
            hasSearched: false
        })
    }

    render(){
        return <div className='mynav-container'>
            <div className='bg-mynav-container' id='bg-mynav-container'></div>
            <div className='container'>
                <div className='row'>
                        <div id='nav-burger-icon' className='col-3'>
                            {/* <div id='nav-burger-icon-font' onClick={this.toggleBurger}><i className='fa fa-bars'></i></div> */}
                            <a href="/">
                                <img src='/img/HEBD.png' id='nav-logo-image2' className='nav-logo-scaled-down' style={{'display': 'none'}}/>
                            </a>
                        </div>
                        <div id='nav-logo' className='col-6'>
                            <a href="/">
                                <img src='/img/HEBD.png' id='nav-logo-image'/>
                            </a>
                        </div>
                        <div id='nav-search-icon' className='col-3'>
                            <div id='social-icons'>
                                <div id='nav-pinterest'><i className='fab fa-pinterest-square'></i></div>
                                <div id='nav-facebook'><i className='fab fa-facebook-square'></i></div>
                                <div id='nav-instagram'><i className='fab fa-instagram'></i></div>
                            </div>
                            <div id='nav-burger-icon-font' onClick={this.toggleBurger}><i className='fa fa-search'></i></div>
                        </div>
                </div>
            </div>
            <div id="burger-open">
                <div className='container'>
                    <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={this.goSearch}>
                                <div className='searchContainer'>
                                    <input type='text' id='searchInput' value={this.state.searchInput} placeholder='Search...' onChange={this.inputHandler}/><button className='btn btn-primary searchBtn' onClick={this.goSearch}>Search</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-sm-12'>
                            <div className='searchContainer-result'>
                                {this.state.uiHandler} {this.state.uiHandlerBlog}
                                { this.state.uiHandler[0] === false && this.state.uiHandlerBlog[0] === false && this.state.hasSearched ? `${this.state.searchInput} is not found.` : ''}
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='searchContainer'>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}


export default withRouter(NavigationBar);