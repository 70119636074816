import React from 'react';
import {mainFirebase} from '../../firebase-config'; 
import "./../top-recipe-bar/style.scss";

class ListCards extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            uiHandler: []
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.querySnapshot !== this.props.querySnapshot){
            this.getPropsData();
        }
    }

    getImage = async filename => {
        if(filename){
            const storageRef = mainFirebase.storage().ref();
            return await storageRef.child(`recipe_image/${filename}`).getDownloadURL()
        }
    }

    loadMore = e => {
        e.preventDefault();
        this.props.loadMore();
    }


    getPropsData = () => {
        this.props.querySnapshot.forEach(async (doc) => {
            const uiHandler = []
            const imgURL = await this.getImage(doc.data().image_filename);
            let rtype = 'Breakfast';
            if(doc.data().rtype === '2'){
                rtype = 'Lunch';
            } else if (doc.data().rtype === '3'){
                rtype = 'Dinner'
            } else if (doc.data().rtype === '4') {
                rtype = 'Snacks'
            }
            const mystyle = {
                height: '200px'
            }
            uiHandler.push((
                <div className="col-lg-4 col-sm-6" key={doc.id} style={{'marginTop': '30px'}}>
                    {/* <div className="box grid recipes">
                        <div className="by"><i className="fa fa-cutlery" aria-hidden="true"></i> {rtype}</div>
                        <a href={"/recipe-detail/" + doc.id}><img style={mystyle} src={imgURL} alt="" /></a>
                        <h2><a href={"/recipe-detail/" + doc.id}>{doc.data().title}</a></h2>
                        <p>{doc.data().description}</p>
                        <div className="tag">
                            {doc.data().ingredients[0] ? <a href="#">{doc.data().ingredients[0]}</a> : ''}
                            {doc.data().ingredients[1] ?  <a href="#">{doc.data().ingredients[1]}</a> : ''}
                            {doc.data().ingredients[2] ? <a href="#">{doc.data().ingredients[2]}</a> : ''}
                        </div>
                    </div> */}
                    <div className='side-card'>
                        <div className="card__tag-wrapper">
                            <span className="card__triangle"></span>
                                <p className="card__tag">
                                    {rtype}
                                </p> 
                            <span className="card__triangle--flipped"></span>
                        </div>
                        <img src={imgURL} alt="" className='side-image' />
                        <div className='side-card-container'>
                            <h4><a href={"/recipe-detail/" + doc.id}>{doc.data().title}</a></h4>
                        </div>
                    </div>
                </div>
            ))
            this.setState({uiHandler: [...this.state.uiHandler, uiHandler]})
        });
    }


    render(){
        return(
            <div className="list">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h5><i className="fa fa-cutlery" aria-hidden="true"></i> All Recipes</h5>
                        </div>
                        { this.state.uiHandler }
                        <div className="col-lg-12 text-center" style={{'marginTop': '30px'}}>
                            {
                                this.props.loadSpinner ? 
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : 
                                    this.props.hideLoadMoreIcon ?
                                    '' :  <a href="#" className="btn btn-load" onClick={this.loadMore}>Load More</a>
                            }
                        </div>
                    </div>
                </div>
            </div>  
        )
    }
}

export default ListCards;