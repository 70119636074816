import React from 'react';
import {mainFirebase} from '../../firebase-config';
import "./../top-recipe-bar/style.scss";

class BlogsList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            uiHandler: []
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.querySnapshot !== this.props.querySnapshot){
            this.getPropsData();
        }
    }

    getPropsData = () => {
        this.props.querySnapshot.forEach(async (doc) => {
            const uiHandler = []
            const imgURL = await this.getImage(doc.data().image_filename);
            uiHandler.push((
                <div className='col-lg-4 col-md-4 animate-top'>
                    <div className='side-card'>
                        
                        <img src={imgURL} alt="" className='side-image' />
                        <div className='side-card-container'>
                            <h4><a href={`/blog-detail/${doc.id}?title=${doc.data().title}`}>{doc.data().title}</a></h4>
                        </div>
                    </div>
                </div>
            ))
            this.setState({uiHandler: [...this.state.uiHandler, uiHandler]})
        });
    }

    getImage = async filename => {
        if(filename){
            const storageRef = mainFirebase.storage().ref();
            return await storageRef.child(`blogs_image/${filename}`).getDownloadURL()
        }
    }

    loadMore = e => {
        e.preventDefault();
        this.props.loadMore();
    }

    render(){
        return(
            <div className="top">
                <div className="container">
                    <div className='main-title'>
                        <div className='main-title-sub'>
                            <i className="fa fa-cutlery" aria-hidden="true"></i> 
                            <a href="/blogs?category=0&limit=6">Blogs >>></a>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {this.state.uiHandler}
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogsList;