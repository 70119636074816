import React from 'react';
import firebase from 'firebase';
import {mainFirebase} from './../../firebase-config';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
    }
};
class Profile extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            uiHandler: '',
            currentUser: '',
            isSignedIn: false
        }
        this.firestore = mainFirebase.firestore();
    }

    componentWillUnmount(){
        this.unregisterAuthObserver();
    }

    componentWillMount(){
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => {
                if(user){
                    this.setState({currentUser: user, isSignedIn: !!user})
                    this.renderCreditCard();
                }
            })
    }

    handleTextChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    removeCard = (e, id) => {
        e.preventDefault();
        this.firestore.collection('stripe_customers').doc(`${this.state.currentUser.uid}`).collection('sources').doc(id).delete().then(data => {
            this.renderCreditCard();
        })
    }

    renderCreditCard = e => {
        if(e) e.preventDefault();
        this.firestore.collection('stripe_customers').doc(`${this.state.currentUser.uid}`).collection('sources').get().then(data => {
            if(!data.empty){
                data.forEach(doc => {
                    // const id = doc.id;
                    this.setState({uiHandler: 
                        <div className='container'>
                            <div className = 'row'>
                                <div className='col-md-6'>Registered Card: </div>
                                <div className='col-md-6'>{
                                    doc.data().brand + ' ******' + doc.data().last4
                                }  | <a style={{'color': 'red'}} href='#' onClick={e => this.removeCard(e, doc.id)}>Remove</a></div>
                            </div>
                        </div>
                    })
                })
            } else {
                this.setState({uiHandler: 
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6'>Credit Card Number: </div>
                            <div className='col-md-6'><input type='tel' pattern="[0-9]*" id='c_number' onChange={this.handleTextChange} /></div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>CVC: </div>
                            <div className='col-md-6'><input type='number' id='c_cvc' onChange={this.handleTextChange} /></div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>Expiration (Month): </div>
                            <div className='col-md-6'><input type='number' id='c_expiration_month' onChange={this.handleTextChange}  /></div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>Expiration (Year): </div>
                            <div className='col-md-6'><input type='number' id='c_expiration_year' onChange={this.handleTextChange}  /></div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'></div>
                            <div className='col-md-6'><button className='btn btn-primary' onClick={this.submitCreditCard}>Submit</button></div>
                            
                        </div>
                    </div>
                })
            }
        })
    }

    submitCreditCard = e => {
        e.preventDefault();
        window.Stripe.card.createToken({
            number: this.state.c_number,
            cvc: this.state.c_cvc,
            exp_month: this.state.c_expiration_month,
            exp_year: this.state.c_expiration_year
          }, (status, response) => {
            if (response.error) {
              this.newCreditCard.error = response.error.message;
            } else {
              firebase.firestore().collection('stripe_customers').doc(this.state.currentUser.uid).collection('tokens').add({token: response.id}).then(() => {
                  alert('Card Successfully Registered!');
                  window.location.reload();
              });
            }
        });
    }

    render(){
        return(
            <React.Fragment>
                <div className='list limit-height'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                {
                                    this.state.isSignedIn ? <h5>Your Information</h5> : <h5>Login Using Your Google Account</h5>
                                }
                                
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-9 page-content col-thin-left'>
                                <div className='category-list make-grid'>
                                    <div className='adds-wrapper row no-margin'>
                                        <div className='container'>
                                            {
                                                this.state.isSignedIn ?
                                                <React.Fragment>
                                                    <div className='row'>
                                                        <div className='col-md-6'>Name: </div>
                                                        <div className='col-md-6'>{this.state.currentUser.displayName} | <a style={{'color': 'red'}} onClick={() => {firebase.auth().signOut(); window.location.href = '/'}}>Sign-out</a></div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6'>Email: </div>
                                                        <div className='col-md-6'>{this.state.currentUser.email}</div>
                                                    </div>
                                                </React.Fragment> :  
                                                <div className='row'>
                                                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
                                                </div>
                                            }
                                            
                                        </div>
                                        {this.state.uiHandler}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </React.Fragment>
        )
    }
}

export default Profile;