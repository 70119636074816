import React from 'react';
import {mainFirebase} from './../../firebase-config';

class RecipeDetail extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            imgUrl: ''
        }
    }

    componentDidMount(){
        const db = mainFirebase.firestore();
        const docRef = db.collection('recipes').doc(this.props.match.params.id);
        docRef.get().then((doc) => {
            if (doc.exists) {
                this.setState({data: doc.data()});
                this.getImgUrl(doc.data().image_filename);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    getImgUrl = filename => {
        if(filename){
            const storageRef = mainFirebase.storage().ref();
            storageRef.child(`recipe_image/${filename}`).getDownloadURL().then(url => {
                this.setState({imgUrl: url});
            })
        }
    }

    renderIngredients = item => {
        const uiHandler = [];
        item.forEach(i => {
            console.log(i)
            uiHandler.push((
                <li>{i}</li>
            ))
        })

        return uiHandler;
    }

    renderDirections = item => {
        const uiHandler = [];
        item.forEach(i => {
            console.log(i)
            uiHandler.push((
                <li>{i}</li>
            ))
        })

        return uiHandler;
    }


    render(){
        const ingredients = this.state.data.ingredients;
        const directions = this.state.data.directions;
        const myDate = new Date(this.state.data.date_added * 1000).toDateString();
        console.log(this.state.data.date_added)
        return(
            <div className="recipe-detail">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            {/* <h4>{myDate}</h4> */}
                            <h1>{this.state.data.title}</h1>
                            {/* <div className="by"><i className="fa fa-user" aria-hidden="true"></i> Gerina Amy</div> */}
                        </div>
                        <div className="col-lg-8">


                            <img src={this.state.imgUrl} alt="" />


                            <div className="info">
                                <div className="row">
                                    {/* <div className="col-lg-4 col-sm-4">
                                        <p>Serves:</p>
                                        <p><strong><i className="fa fa-users" aria-hidden="true"></i> 4 people</strong></p>
                                    </div>
                                    <div className="col-lg-4 col-sm-4">
                                        <p>Prep Time:</p>
                                        <p><strong><i className="fa fa-clock-o" aria-hidden="true"></i> 30 min</strong></p>
                                    </div>
                                    <div className="col-lg-4 col-sm-4">
                                        <p>Cooking:</p>
                                        <p><strong><i className="fa fa-clock-o" aria-hidden="true"></i> 1 Hour</strong></p>
                                    </div> */}
                                </div>
                            </div>


                            <p>{this.state.data.description}</p>

                            {ingredients !== undefined ? <div className="tag">
                                {ingredients[0] ? <a href="#">{ingredients[0]}</a> : ''}
                                {ingredients[1] ?  <a href="#">{ingredients[1]}</a> : ''}
                                {ingredients[2] ? <a href="#">{ingredients[2]}</a> : ''}
                            </div> : ''}

                            <div className="ingredient-direction">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <h3>Ingredients</h3>
                                        {ingredients !== undefined ? <ul className="ingredients">
                                            {this.renderIngredients(ingredients)}
                                        </ul> : ''}
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <h3>Directions</h3>
                                        
                                            {
                                                directions !== undefined ? 
                                                    <ol className="directions">
                                                        {this.renderDirections(directions)}
                                                    </ol> : ''
                                            }
                                       
                                    </div>
                                </div>
                            </div>



                            {/* <div className="nutrition-facts clearfix">
                                <h3>Nutrition Facts</h3>
                                <div>
                                    <p>Calories:</p>
                                    <p><strong>632 kcal</strong></p>
                                </div>
                                <div>
                                    <p>Carbohydrate:</p>
                                    <p><strong>37 g</strong></p>
                                </div>
                                <div>
                                    <p>Fat:</p>
                                    <p><strong>92 g</strong></p>
                                </div>
                                <div>
                                    <p>Protein:</p>
                                    <p><strong>63 g</strong></p>
                                </div>
                                <div>
                                    <p>Cholesterol:</p>
                                    <p><strong>0 mg</strong></p>
                                </div>

                            </div> */}


                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RecipeDetail;