import React from 'react';
import ListCards from '../../components/list-cards/recipes';
import {mainFirebase} from './../../firebase-config';

class Recipe extends React.Component{
    constructor(props){
        super(props);
        this.firestore = mainFirebase.firestore();
        this.state = {
            recipeData: [],
            lastVisible: '',
            loadSpinner: false
        }
    }

    componentDidMount(){
        this.setState({loadSpinner: true});
        this.firestore.collection("recipes").limit(6).get().then((querySnapshot) => {
            this.setState({recipeData: querySnapshot, lastVisible: querySnapshot.docs[querySnapshot.docs.length-1], loadSpinner: false});
        });
    }

    loadMore = () => {
        if(this.state.lastVisible !== undefined){
            this.setState({loadSpinner: true});
            this.firestore.collection("recipes").startAfter(this.state.lastVisible).limit(6).get().then((querySnapshot) => {
                this.setState({recipeData: querySnapshot, lastVisible: querySnapshot.docs[querySnapshot.docs.length-1], loadSpinner: false});
            });
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className='limit-height'>
                    <ListCards querySnapshot={this.state.recipeData} loadMore={this.loadMore} hideLoadMoreIcon={this.state.lastVisible === undefined} loadSpinner={this.state.loadSpinner}/>
                </div>
            </React.Fragment>
        )
    }
}

export default Recipe;