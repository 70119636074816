import React from 'react';
import {mainFirebase} from './../../firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';
class ItemList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            uiHandler: []
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.products !== this.props.products){
            this.getPropsData();
        }
    }

    getImage = async filename => {
        if(filename){
            const storageRef = mainFirebase.storage().ref();
            return await storageRef.child(`product_image/${filename}`).getDownloadURL()
        }
    }

    purchaseProduct = (e, id, price)=> {
        e.preventDefault();
        if(this.props.isSignedIn){
            if(this.props.hasRegisteredCard){
                const parsedPrice = parseInt((price.toString()).replace('.',''))
                const f = window.confirm('Your registered card will be charged $' + price + '. Do you want to proceed?');
                if(f){
                    this.props.setLoadingOverlay(true, 'Processing payment. Please wait..');
                    mainFirebase.firestore().collection('stripe_customers').doc(this.props.uid).collection('charges').add({
                        source: this.props.cardId,
                        amount: parsedPrice,
                        productId: id
                    }).then(data => {
                        alert('Payment Successful!');
                        this.props.setLoadingOverlay(false, '');
                    }, err => { alert('Error!') });
                }
            } else {
                alert('Please register a card first. Visit My Profile page.')
            }

        } else {
            alert('Please sign in first.')
        }
    }

    getPropsData = () => {
        this.props.products.forEach(async (doc) => {
            const uiHandler = [];
            const imgURL = await this.getImage(doc.data().image_filename);
            uiHandler.push((
                <div className='item-list' key={doc.id}>
                    <div className='row'>
                        <div className="col-md-2 no-padding photobox">
                            <div className="add-image">
                                <a href={"/product-information/" + doc.id}>
                                    <img className="thumbnail no-margin" style={{'height': '200px', 'width': '200px'}} src={imgURL} alt="img" />
                                </a>
                            </div>
                        </div>
                        <div className="add-desc-box col-md-7">
                            <div className="ads-details">
                                <h5 className="add-title">
                                    <a href={"/product-information/" + doc.id}>{doc.data().title}</a>
                                </h5>
                                <span className="info-row"> 
                                    {/* <span className="item-location"><i className="fa fa-map-marker-alt"></i> {doc.data().description} </span> */}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-3 text-right  price-box">
                            <h2 className="item-price"> $ {doc.data().price} </h2>
                            <a className="btn btn-primary" style={{'color':'white'}} onClick={e => this.purchaseProduct(e, doc.id, doc.data().price)}> <FontAwesomeIcon icon={faShoppingCart} />  <span>Buy</span></a>
                        </div>
                    </div>
                </div>
            ))
            this.setState({uiHandler: [...this.state.uiHandler, uiHandler]})
        });   
        this.props.stopLoading();
    }

    render(){
        return(
            <React.Fragment>
                { this.state.uiHandler }
                <div className="col-lg-12 text-center">
                    {
                        this.props.isLoading ? 
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : ''
                    }
                </div>
            </React.Fragment>
        )
    }

}

export default ItemList;