import React from 'react';

class Footer extends React.Component{
    render(){
        return(
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <h5>About</h5>
                            <p>Nunc at augue gravida est fermentum vulputate. gravida est fermentum vulputate Pellentesque et ipsum in dui malesuada tempus.</p>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <h5>Archive</h5>
                            <ul>
                                <li><a href="#">June 2018</a></li>
                                <li><a href="#">July 2018</a></li>
                                <li><a href="#">August 2018</a></li>
                                <li><a href="#">September 2018</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <h5>Recipes</h5>
                            <ul>
                                <li><a href="browse-recipes.html">Browse Recipes</a></li>
                                <li><a href="/recipe-detail">Recipe Detail</a></li>
                                <li><a href="submit-recipes.html">Submit Recipe</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <h5>Newsletter</h5>
                            <form>
                                <div className="form-group">
                                    <input type="text" className="form-control" />
                                </div>
                                <button type="submit" className="btn">Subscribe</button>
                            </form>
                        </div>
                    </div>
                </div>
            </footer>   
        )
    }
}

export default Footer;