import React from 'react';

import TopRecipeBar from './../../components/top-recipe-bar';
import FeaturedBar from './../../components/featured-tab';
import RecipeList from './../../components/list-bar/recipes';
import BlogList from './../../components/list-bar/blogs';
import {mainFirebase} from './../../firebase-config';

class Home extends React.Component{
    constructor(props){
        super(props);
        this.firestore = mainFirebase.firestore();
        this.state = {
            r_data: [],
            r_lastVisible: '',
            r_loadSpinner: false,
            b_data: [],
            b_lastVisible: '',
            b_loadSpinner: false
        }
    }

    componentDidMount(){
        this.setState({r_loadSpinner: true, b_loadSpinner: true});
        this.firestore.collection("recipes").limit(3).get().then((querySnapshot) => {
            this.setState({r_data: querySnapshot, r_lastVisible: querySnapshot.docs[querySnapshot.docs.length-1], r_loadSpinner: false});
        });
        this.firestore.collection("blogs").limit(3).get().then((querySnapshot) => {
            this.setState({b_data: querySnapshot, b_lastVisible: querySnapshot.docs[querySnapshot.docs.length-1], b_loadSpinner: false});
        });
    }

    r_loadMore = () => {
        if(this.state.r_lastVisible !== undefined){
            this.setState({r_loadSpinner: true});
            this.firestore.collection("recipes").startAfter(this.state.r_lastVisible).limit(3).get().then((querySnapshot) => {
                this.setState({r_data: querySnapshot, r_lastVisible: querySnapshot.docs[querySnapshot.docs.length-1], r_loadSpinner: false});
            });
        }
    }

    b_loadMore = () => {
        if(this.state.b_lastVisible !== undefined){
            this.setState({b_loadSpinner: true});
            this.firestore.collection("recipes").startAfter(this.state.b_lastVisible).limit(3).get().then((querySnapshot) => {
                this.setState({b_data: querySnapshot, b_lastVisible: querySnapshot.docs[querySnapshot.docs.length-1], b_loadSpinner: false});
            });
        }
    }

    render(){
        return(
            <React.Fragment>
                <TopRecipeBar />
                {/* <FeaturedBar /> */}
                <RecipeList querySnapshot={this.state.r_data} loadMore={this.r_loadMore} hideLoadMoreIcon={this.state.r_lastVisible === undefined} loadSpinner={this.state.r_loadSpinner}/>
                <BlogList querySnapshot={this.state.b_data} loadMore={this.b_loadMore} hideLoadMoreIcon={this.state.b_lastVisible === undefined} loadSpinner={this.state.b_loadSpinner}/>
            </React.Fragment>
        )
    }
}

export default Home;