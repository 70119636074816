import React from 'react';
import './family-nav.scss';

class FamilyNavigation extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className='fam-nav'>
                    <div className='fam-title'>The Healthy Eating Family</div>
                    <div className='fam-logo'>logo here</div>
                    <div className='fam-logo'>logo here</div>
                    <div className='fam-logo'>logo here</div>
                </div>
            </React.Fragment>
        )
    }
}

export default FamilyNavigation;