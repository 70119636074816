import React from 'react';
import ListCards from '../../components/list-cards/blogs';
import {mainFirebase} from './../../firebase-config';
import queryString from 'query-string'

class Blogs extends React.Component{
    constructor(props){
        super(props);
        this.firestore = mainFirebase.firestore();
        this.defaultLimit = 6;
        this.state = {
            recipeData: [],
            lastVisible: '',
            loadSpinner: false,
            rtype: '0'
        }
    }

    componentDidMount(){
        const values = queryString.parse(this.props.location.search); 
        this.loadSelectedType(values.category, values.limit);
    }

    loadSelectedType = (rtype, limit) => {
        this.setState({loadSpinner: true, recipeData: []});
        const setLimit = limit - this.defaultLimit;
        try{
            if(rtype !== "0"){
                this.firestore.collection("blogs").where("rtype", "==" , rtype).limit(this.defaultLimit + setLimit).get().then((querySnapshot) => {
                    this.setState({recipeData: querySnapshot, rtype: rtype, lastVisible: querySnapshot.docs[querySnapshot.docs.length-1], loadSpinner: false});
                });
            } else {
                this.firestore.collection("blogs").limit(this.defaultLimit + setLimit).get().then((querySnapshot) => {
                    this.setState({recipeData: querySnapshot, rtype: rtype, lastVisible: querySnapshot.docs[querySnapshot.docs.length-1], loadSpinner: false});
                });
            }
        } catch(e){
            window.location = '/';
        }
    }

    loadMore = () => {
        if(this.state.lastVisible !== undefined){
            this.setState({loadSpinner: true});
            try{
                if(this.state.rtype === "0"){
                    this.firestore.collection("blogs").startAfter(this.state.lastVisible).limit(this.defaultLimit).get().then((querySnapshot) => {
                        this.setState({recipeData: querySnapshot, lastVisible: querySnapshot.docs[querySnapshot.docs.length-1], loadSpinner: false});
                    });
                } else {
                    this.firestore.collection("blogs").where("rtype", "==" , this.state.rtype).startAfter(this.state.lastVisible).limit(this.defaultLimit).get().then((querySnapshot) => {
                        this.setState({recipeData: querySnapshot, lastVisible: querySnapshot.docs[querySnapshot.docs.length-1], loadSpinner: false});
                    });
                }
            } catch(e) {
                window.location = '/';
            }
        }
    }

    handleType = e => {
        e.preventDefault();
        window.location = `/blogs?category=${e.target.value}&limit=${this.defaultLimit}`;
    }

    render(){
        return(
            <React.Fragment>
                <div className='limit-height'>
                    <div className="list">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <select value={this.state.rtype} onChange={this.handleType} className="form-control m-b">
                                        <option value="0">All Blogs</option>
                                        <option value="1">Health and Wellbeing</option>
                                        <option value="2">Exercise and Fitness</option>
                                        <option value="3">Foods and Drinks</option>
                                        <option value="4">Home and Garden</option>
                                        <option value="5">Holiday</option>
                                        <option value="6">Entertainment</option>
                                        <option value="7">Weight Loss</option>
                                    </select>
                                </div>
                                <ListCards querySnapshot={this.state.recipeData} loadMore={this.loadMore} hideLoadMoreIcon={this.state.lastVisible === undefined} loadSpinner={this.state.loadSpinner}/>
                            </div>
                        </div>
                    </div>  
                </div>
            </React.Fragment>
        )
    }
}

export default Blogs;