import React from 'react';
import './style.scss';
class QuickTips extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className='list'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h5>366 Cooking Tips</h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
<h2>Baking</h2>
<div className='q_container'>
    <div className='tips-image'>
        <img src='/img/baking.png' />
    </div>
    1.	You can tell if a cake or loaf is done if you stick a toothpick in the middle and it comes out clean. <br />  

    2.	You can tell if muffins are done by lightly pressing them. If they spring back, they’re done. <br />  

    3.	Bake a certain recipe often? Double the batch, allow to cool completely, place in airtight containers and freeze. <br />  

    4.	Butter makes the best homemade piecrust, but shortening crusts can save you money. <br />  

    5.	When making piecrust, don’t overwork the dough and keep your water and butter very cold to prevent the butter from melting. <br />  

    6.	To make sure your butter is very cold while making piecrust, chop it into small pieces and place in the freezer for about an hour. <br />  

    7.	To keep your water cold while making piecrust, simply keep a glass of ice water close and measure out the water as you need it. <br />  

    8.	While rolling pie dough, be generous with the flour on your rolling pin, rolling surface and on your dough. It will make it easier to work with. <br />

    9.	If you don’t have quite enough crust to top all your pies, there’s nothing to worry about. Make a design or roll out the crust as far as it goes. It will still look wonderful and taste delicious. <br />

    10.	Have extra piecrust? Make into small shapes and top with cinnamon and sugar before baking. It’s delicious!  <br />

    11.	If a recipe doesn’t specify egg size, you can assume you should use large eggs. <br />  

    12.	If your baking sheets become dark and crusted, line them with foil before baking and recycle when you’re done. <br />  

    13.	Always preheat your oven before baking so that you can ensure even cooking. <br />  

    14.	When a recipe calls for beating butter, plan ahead and make sure your butter is at room temperature. <br />  

    15.	Use your judgment and experience when deciding whether you need to grease a pan or not. If you have high quality non-stick surfaces, it’s likely you don’t need to add any extra fat to your baking. <br />

    16.	Try an ice cream scooper to pour your muffin batter into muffin pans. You’ll get just the right amount and fewer drips. <br />  

    17.	Add some nutritional value to your pancakes. Add pureed sweet potato or pumpkin for great flavor too. <br />  

    18.	Check cookies at the minimum baking time. Cookies are done as soon as they start browning around the edges. <br />  

    19.	When baking most items, place the oven rack in the middle position. <br />  

    20.	Don’t want your cake to stick to your serving platter? Dust the platter with some icing sugar. <br />  

    21.	If you want chewy cookies, use melted butter. If you like them more cake-like, use more eggs. <br />  

    22.	A baker’s dozen is 13. Baking in 13s helps in arrangement and allows items to bake more evenly. <br />  

    23.	For a shiny golden bread crust, brush your bread with an egg wash before baking. Make it by beating 1 egg and 1 tablespoon of milk. <br />  

    24.	Brown sugar is measured “packed”. That means you should press it firmly into your dry measure cup to ensure you have the right amount. <br />  

    25.	Uneven temperature in your oven? Rotate your cookies and other baked items part way through for more evenness. <br />  

    26.	Wants a nice steady supply of cookies? Most cookie dough is easily refrigerated or frozen. Most already baked cookies freeze well too. <br />  

    27.	If you need a cake or iced cupcakes for a special occasion, but have a tight schedule, you can bake or freeze your cake or cupcakes ahead of time. Defrost and apply your frosting before the big event. <br />

    28.	Most frosting spreads best at room temperature, so plan ahead when you need to ice a cake. <br />  

    29.	Give your baking a veggie boost: carrot muffins, chocolate zucchini loaf and pumpkin cookies. <br />  

    30.	Cool your cake completely before frosting or your icing will melt!  <br />

    31.	Peanut butter cookies taste divine with melted chocolate drizzled on them and hardened. Two great tastes in one. <br />
<br />
<h2>Basics</h2>

<div className='tips-image r'>
    <img src='/img/basic.png' />
</div>

32.	Measure dry ingredients like flour and sugar with dry measuring cups. Fill the cup and scrape a knife over the top to remove extra. <br />  

33.	When measuring wet ingredients like oil or milk, always place the measuring cup on the counter to ensure a level surface. <br />  

34.	Braising is the method of frying food lightly and then stewing in a closed container. <br />  

35.	Stewing is a method of cooking food slowly in liquid. Vegetables and meat are often stewed. <br />  

36.	Roasting, like baking, uses dry heat. However, roasting includes the addition of fat or oil to aid in the cooking process. <br />

37.	Poaching is the method of cooking in a simmering liquid. It differs from stewing in that it produces a thin broth instead of a concentrated one. <br />

38.	Sauté is cooking food in small amounts of fat or oil in a pan at a fairly high heat. <br />  

39.	Boiled egg simmering times: 12 minutes for hard boiled, 7 minutes for medium, 4 minutes for soft (large eggs). <br />  

40.	Make it easier to remove shells from boiled eggs by running cold water over your pot of eggs for a few minutes. <br />  

41.	To separate an egg, you don’t need any special tools. Just crack the egg and pass the yolk back and forth between halves, letting the white drip down into a bowl. <br />  

42.	Basic recipe for cooking long grain white rice: Use 1 part rice to 1 ½ parts water. Add both to a pot; bring to a boil over high heat with lid on. Do not remove the lid - reduce heat to low. Simmer for 20 minutes. Remove from heat and let stand, with lid on, for 5 minutes. <br />  

43.	How to boil potatoes: Peel potatoes and place in pot. Cover with just enough water to cover the potatoes. Place lid on the pot and bring to boil on high heat. Reduce heat to medium-low and simmer for 20 minutes. Strain water and serve.<br />  

44.	How to bake a potato with foil: Clean your potatoes with brush and water. Poke each potato with a fork several times. Wrap with foil and place in a 350 F oven for about 75 minutes.<br />  

45.	How to bake a potato without foil: You don’t actually need foil to bake a potato, but because the skins tend to dry in the baking process, you can brush them with oil. To start, scrub your potatoes with a brush and water. Poke each potato with a fork several times. Brush lightly with olive oil and place them directly on the middle rack at 350F. Make sure you place a pan or something under them to catch the oil drippings. Bake for about 75 minutes.<br />  

46.	How to cook pasta: Fill a large pot with water, add a few drops of olive oil to prevent sticking. You can also add salt for flavoring. Bring to a boil over high heat and add uncooked pasta once it reaches a boil. Once the water reaches a boil again, turn the heat down to medium low and boil according the manufacturer’s cooking times. Once cooked, rinse with water to remove starch and strain completely.<br />  

47.	When it comes to beef, here’s an explanation of doneness. Rare has a bloody red center (125 F internal temperature), medium rare has a reddish-pink middle (130 F), medium has a thin light pink center (145 F) and well done is cooked all the way through (165 F). <br />  

48.	Pork and poultry should be fully cooked before serving. The way to determine its doneness is with a meat thermometer. Pork is safely cooked at 145 F and poultry is safely cooked at 165 F. Note, any ground meats need to be cooked to an internal temperature of 160 F.<br />  

49.	Read a recipe the whole way through before starting. This will help prevent mistakes along the way. <br />  

50.	When working on a recipe, measure and prepare all your ingredients fist. It makes it easier to follow the recipe and clean up. <br />  

<br />
<h2>Beans and Legumes</h2>
<div className='tips-image'>
    <img src='/img/beans.png' />
</div>

51.	Soak dried beans in 3 times their volume of water overnight before cooking. <br />  

52.	If you’re in a hurry, you can speed up the bean soaking process. Bring water and beans to a boil and remove from heat and soak at room temperature for about an hour.<br />  

53.	The following beans don’t need pre-soaking because they have thin skins: lentils, split peas and mung beans.<br />  

54.	1 cup of dry beans = approximately 3 cups cooked. <br />  

55.	1 pound of beans = approximately 6 cups cooked. <br />  

56.	Your beans are done cooking when you can mash them easily with a fork. <br />  

57.	When cooking beans, make sure to simmer and avoid high heat as it can damage the beans. <br />  

<br />
<h2>Camp Cooking</h2>
<div className='tips-image r'>
    <img src='/img/camp.png' />
</div>
58.	Meal planning is essential when camping. Make sure you have enough food and that you eat well. <br />  

59.	Save on time when cooking at the campground, pre-cut vegetables and meat, marinate and store in serving-sized containers. <br />  

60.	You can reduce the number of dishes to clean when cooking by “freezer bag cooking”. It’s a method of adding boiling water to freezer bags to cook your meals. <br />  

61.	A few easy make cooking items: Boil in bag rice, smokies and wieners cooked over the fire, noodles in a cup, cook canned foods in the can over the fire and more. <br />  

62.	When camping, fully prepare soups, stews and other one-dish items ahead of time. Just reheat while you’re in the woods. <br />  

63.	To avoid attracting bears and other unwanted visitors, cook as far away from your tent as possible. Always throw away all garbage and wash your dishes…away from your sleeping quarters.<br />  

64.	A Dutch oven is a great investment for your campfire cooking. You can make just about anything you make in a regular oven. <br />  

65.	Camp foods that need very few dishes: Hot dogs and smokies cooked on a stick, baked potatoes cooked on coals, meats cooked in a roasting cage and s’mores. <br />  
<br />
<h2>Conversions</h2>
<div className='tips-image'>
    <img src='/img/conversion.png' />
</div>
66.	Measurement Abbreviations: C = Celsius / c = cup / F = Fahrenheit / g or gr = gram / kg = kilogram / mL = milliliter / L = liter /  oz = ounce /  t or tsp = teaspoon / T or tbsp = tablespoon / <br />  

67.	Approximate Weight Conversion: 1 ounce equals 30 grams. <br />  

68.	Approximate Temperature Conversion: 350 Fahrenheit equals 180 celsius. 375 F is 190 C. 400 F is 200 C. 450 F is 230 C. <br />  

69.	Approximate Metric Conversion: 1 teaspoon is 5 milliliters (mL). 1 tablespoon is 15 mL. 1 cup equals 250 mL . 1 quart is 1 liter (L). 1 gallon is 4 L. <br />  

70.	Flour Conversion: 1 cup = 4.5 oz = 128 grams  <br />  

71.	Rolled Oats Conversion: 1 cup = 3 oz = 135 grams  <br />  

72.	Butter Conversion: 1 cup = 8 oz = 225 grams  <br />  

73.	Sugar Conversion: 1 cup = 7 oz = 200 grams  <br />  

74.	Brown Sugar Conversion: 1 cup = 7.7 oz = 220 grams  <br />  

75.	Unsifted Icing Sugar Conversion: 1 cup = 4.5 oz = 128 grams  <br />  

76.	Vegetable Oil Conversion: 1 cup = 7.7 oz  = 220 grams  <br />  

77.	Shortening Conversion: 1 cup = 7.5 oz = 212 grams  <br />  
<br />
<h2>Dairy</h2>
<div className='tips-image r'>
    <img src='/img/dairy.png' />
</div>
78.	Is moldy cheese safe to eat? Generally speaking, soft cheeses that mold should be thrown out. This includes cottage cheese, cream cheese and even any type of cheese that’s been shredded. If it’s a harder cheese like Swiss or cheddar, it’s likely safe to cut off the mold, but be safe and cut about an inch past the mold.<br />  

79.	Make creamier soups by substituting some of the liquids with milk. <br />  

80.	If milk or other dairy products have an unusual odor or taste, discard them even if the expiration date hasn’t passed. <br />  

81.	There is no right or wrong cheese for a pizza. Go with what you like and what you have on hand. <br />  

82.	Skip the expensive protein-fortified milk. Get your protein from natural sources instead. <br />  

83.	Semi-soft to hard cheeses tolerate heat better than soft cheeses, so keep that in mind when cooking. <br />  

84.	Soft cheeses include brie, camembert, cream cheese, feta and ricotta. <br />  

85.	Semi-soft cheeses include mozzarella, jack, Gouda, havarti and provolone. <br />  

86.	Semi-firm cheeses include asiago, cheddar, Colby, Edam, emmental, Gruyere and Swiss. <br />  

87.	Firm cheeses include aged asiago, dry jack, Romano and Parmesan. <br />  

88.	Did you know that many low and no fat dairy products contain more chemicals and non-food ingredients than their full fat counterparts?  <br />  
<br />
<h2>Equipment</h2>
<div className='tips-image'>
    <img src='/img/equipment.png' />
</div>
89.	Basic baking equipment: Wet measuring cup, dry measure, measuring spoons, baking sheets, muffin pan, square cake pan, oblong cake pan, loaf pan and pie pan. Don’t forget a whisk and/ or mixer, mixing bowls in various sizes, large mixing spoons and a spatula. <br />  

90.	Basic pot set: Large pot for cooking pastas and boiling potatoes, medium and small sauce pans, frying pans in at least 2 sizes. <br />  

91.	Making piecrust from scratch? You’ll need a pastry cutter or food processor, glass mixing bowl, rolling pin and a pie pan. <br />  

92.	Roasting Meats? Equipment to have on hand includes a roasting pan, baster and meat thermometer. Of course, you’ll need a carving knife and fork for serving.<br />  

93.	Essential equipment for the gas BBQ: Tongs, spatula, basting brush, meat thermometer and cleaning brush. <br />  

94.	Knife set essentials: 6-10” chef’s knife, small paring knife and serrated bread knife. <br />  

95.	Essential kitchen prep tools: Knives, cutting board, can opener, vegetable peeler and a cheese grater. <br />  

96.	More tools for cooking: Strainer, tongs, spatula, salt and pepper shakers, oven mitts and storage containers. <br />  

97.	Silicone oven mitts are more heat resistant and often have better grips than traditional oven mitts. <br />  

98.	Pots and pans with metal handles allow for more versatile cooking and movement between stovetop and oven. Just beware; they get hot, so use mitts!<br />  

99.	Because cutting boards can absorb bacteria, having a separate one for raw meats may be a good idea. <br />  

100.	While a salad spinner may seem like an extravagance, it’s likely a money saver in the long run. It makes it easy to clean and dry your lettuce to reduce spoilage.<br />  

101.	A hand blender is a very versatile tool that allows you to blend, chop and whip and it’s easy to clean up. <br />  

102.	Do your homework when shopping for kitchen essentials. Go for quality over price, but do keep your budget in mind. <br />  
<br />
<h2>Encouragement and Motivation</h2>
<br />
103.	If a recipe doesn’t turn out quite like you’d hoped, don’t be discouraged. You’re your worst critic and chances are everyone else will think it turned out fine. <br />

104.	Don’t be afraid to experiment. It’s okay to substitute ingredients when you don’t have certain ones or you have certain preferences. <br />  

105.	If you’ve made it a goal to cook at home more often, but fall off the wagon and find yourself in the McDonald’s line up, don’t be discouraged. You can start again tomorrow. <br />

106.	"Strange to see how a good dinner and feasting reconciles everybody." ~ Samuel Pepys  <br />

107.	"Hunger finds no fault with the cooking." ~ Proverb  <br />

108.	"Cooking is like love. It should be entered into with abandon or not at all." ~ Harriet Van Horne <br />  

109.	“Cookery is not chemistry. It is an art. It requires instinct and taste rather than exact measurements.” ~ Marcel Boulestin  <br />

110.	“One cannot think well, love well, sleep well, if one has not dined well.” ~ Virginia Woolf  <br />

111.	“The only time to eat diet food is while you're waiting for the steak to cook.” ~ Julia Child  <br />
<br />
<h2>Entertaining</h2>

112.	When making a meal for a large group, plan well in advance. Find out about any allergies or special dietary requirements. <br />  

113.	When entertaining a large group, get as much of your food preparation done as possible the few days before. <br />  

114.	On the day of your dinner party, make a timeline so that you know when to do the final preparation on each item. This will make sure everything is done on time. <br />

115.	When making your dinner party grocery list, calculate about ½ pound of meat per guest. <br />  

116.	Reduce stress on your next dinner party; cook something you’re very familiar with. <br />  

117.	If it’s your first dinner party, keep it simple. Keep the meal simple and aim for about 6-8-guest maximum. <br />  

118.	Have a small appetizer plate out for your dinner guests when they arrive. That way, you have some time to prepare the first course of your official meal.<br />


119.	Who says you have to do all the food preparation for a party? Ask your guests to contribute a favorite dish or dessert. <br />  

120.	For your dinner party dessert, consider offering a healthy option (like fresh fruit), in addition to something a little more sinful. <br />

121.	If you’re organizing a potluck, keep track of what everyone is bringing so you don’t have duplicates. Don’t be shy about asking for certain items if they’re needed to round out the menu.<br />

122.	Individual potluck dishes need not be in serving sizes for each guest. After all, if there are a lot of choices, everyone can eat a small serving and not everyone will try each food item. <br />

123.	Make clean up after entertaining easier…make sure your dishwasher is empty before you start. <br />  

124.	Bringing food to a potluck? Bring it in the dish you will serve it in so you don’t give your hostess extra work and stress. <br />  

125.	If you are bringing a dish to a potluck that needs heating or further preparation, make sure to let your hostess now, so she can be prepared. <br />
<br />
<h2>Fish and Seafood</h2>
<div className='tips-image r'>
    <img src='/img/fish.png' />
</div>
126.	If you want to ensure fish is fresh, press your finger on it. If it springs back, you’ve got fresh fish. <br />  

127.	A few ways you can cook fish: grilled, roasted, steamed, poached, baked and fried.   <br /> 

128.	Want to grill a fish? Choose a sturdier variety like salmon, tuna or swordfish. <br />  

129.	Lemon juice can help a fish retain its color during cooking and it enhances the flavor. <br />  

130.	Never overcook fish or the results will be dry and flavorless. <br />  

131.	Fish is generally low fat and a good source of omega-3 fats that help prevent blood clots that may lead to heart attack and stroke. <br />  
132.	Fish should be cooked to an internal temperature of 145 F for safety. <br />  

133.	Cook shrimp and prawns in their shells so they’ll retain their moisture and flavor. <br />  

134.	Clams and mussels should open as they cook. You can pry closed ones open to see if they are good, but if they’re spoiled, toss them. <br />  

135.	Cephalopods like octopus and squid should be cooked quickly over high heat or very slowly over low heat. Don’t overcook or the result will be rubbery. <br /> 

136.	Always marinate your seafood in the fridge…never at room temperature. <br />  

137.	Thaw seafood in the fridge or in cold running water. You can also use your microwave’s defrost setting if you’re going to use the seafood right away. <br /> 

138.	Crab and lobster shells turn bright red when cooked. <br />  

139.	For most fish, you can tell if it’s done if it’s opaque in the middle and still retains its moisture. Fatty fish like salmon or tuna can be cooked with a more translucent center. <br />  
<br /> 
<h2>Fondue</h2>
<div className='tips-image'>
    <img src='/img/fondue.png' />
</div>
140.	Chocolate Fondue Dippers: Sliced bananas, strawberries, mandarin oranges, marshmallows and pineapple slices. <br />  

141.	Cheese Fondue Dippers: French bread, garlic sausage, steamed asparagus or cauliflower, mushrooms, apple slices, grapes, tortellini and steak.<br /> 

142.	Oil Fondue Dippers: Tempera battered vegetables and seafood, meatballs, sausage, steak, chicken and pork. You can even dip bread in egg and make fried French toast.<br /> 

143.	Broth Fondue Dippers: Udon noodles, vegetables and pre-cooked meat. <br />  

144.	Debating oil or an electric fondue pot? Electric is less messy, less expensive to run, allows for consistent heat and is safer. Oil is more portable in that you don’t have to be close to an electrical outlet.<br /> 

145.	Chocolate fondue is best done in an electric pot intended for chocolate. Or if you’re using flame, melt the chocolate first and place in a ceramic pot over a votive candle. The lighter heat source will prevent burning.<br /> 

146.	Many fondue sticks come with different colors. Use this coding to identify everyone’s food in the pot. If you don’t have color-coding or have too many guests, you can label the handle with masking tape. <br /> 
<br /> 
<h2>Freezing</h2>
147.	Allow food to cool completely before freezing. Moisture on food can create freezer burn. <br />  

148.	When freezing food in containers, use as small a container as possible. You don’t want extra air in your container. <br />  

149.	When freezing food in freezer bags, squeeze the air out as much as possible to avoid freezer burn. <br />  

150.	User containers and bags marked for use in a freezer. You will get much better results.   <br />

151.	While nobody likes freezer burn and it results in lower quality food, it is NOT unsafe to eat freezer burned food. <br />  

152.	You can freeze eggs. Crack and beat them and place them in an air tight container. <br />  

153.	Note that meat packaging from your grocery store is not air tight. If you plan to freeze meat for more than a month or two, you should repackage it. <br />

154.	Always label your frozen items. Make sure to note what the contents are and the date they were made. <br />  

155.	Organize your freezer so you can easily find what you’re looking for. Place similar food types together and place the oldest items near the top/front.<br />

156.	Thaw frozen foods in the refrigerator and not on the counter or you may encourage bacteria growth. <br />  

157.	If you plan to use frozen foods right away, you can defrost them in a microwave, using the microwave’s defrost setting. <br />  

158.	If you’ve only used part of a can of tomato paste, you can wrap the rest in tin foil, place in a ziploc bag and freeze. <br />  

159.	Always freeze foods in ready-to-use portions. That way, you don’t have extra when it’s time to defrost. <br />  
<br />
<h2>Fruits</h2>
<div className='tips-image r'>
    <img src='/img/fruits.png' />
</div>
160.	Want to squeeze more juice from your citrus fruits? Heat them first. You can do this in the microwave for about 15 seconds or by soaking in hot water for a few minutes.<br />

161.	Add fresh fruit to yogurt or oatmeal for a pleasant texture and added vitamin boost. <br />  

162.	Fruit or berries add a deliciously surprising new taste and color to green salads. <br />  

163.	Grilled or baked fruits make for a delicious and healthy dessert. <br />  

164.	A variety of fruits can be canned, including apples, berries, grapes, peaches, pears, nectarines, cherries, pineapple and more. <br />  

165.	Fruit salsa makes a great addition to a nice piece of fish. <br />  

166.	Citrus fruits add wonderful flavor to poultry. Use citrus in brining solutions. You can also poke a few holes in an orange or lemon and place it in the cavity before cooking.<br />

167.	Making homemade ice cream? Add fresh fruit for a nutrition boost. <br />  
<br />
<h2>Grains</h2>
<div className='tips-image'>
    <img src='/img/grains.png' />
</div>
168.	A few drops of lemon juice can help your rice grains from sticking while cooking. <br />  

169.	A few drops of olive oil can help your pasta noodles from sticking while cooking. <br />  

170.	Add chicken or vegetable stock to your water when cooking rice for extra flavor. <br />  

171.	Add coconut milk to your water when cooking rice for extra flavor. <br />  

172.	Whole grains are better for you, so use them readily. They provide longer energy and better nutritional benefit. <br />  

173.	When it comes to grains, there are so many options. Think barley, buckwheat, couscous, quinoa and more. <br />  
<br />
<h2>Grilling</h2>
<div className='tips-image r'>
    <img src='/img/grill.png' />
</div>
174.	Always preheat your grill fully for best results. This can take up to 15 minutes. <br />  

175.	Charcoal grilling provides great flavor, but gas is easier for even cooking. <br />  

176.	Many recommend continuous flipping of meats while grilling. This can actually dry out meats, so avoid flipping over and over. <br />  

177.	Avoid sugary marinades (teriyaki, barbecue sauce) on meats you are going to grill. Add these sauces near the end of the grilling process to avoid sticking to the grill.<br />

178.	When you’re done grilling, leave your barbecue on high with the lid closed for about 10 minutes. Once it cools slightly, scrape it with a brush. <br />

179.	If you don’t have a grill brush, crumple up a ball of aluminum foil and with tongs, scrape the foil on your grill. <br />  

180.	Cooking with wooden skewers? Soak them in water for about 30 minutes before grilling. <br />  

181.	Put a light coating of olive oil on vegetables you’re going to grill. This will help keep them moist and bring out flavor. <br />  

182.	To grill ribs, you can boil them for an hour first, marinate and then grill at medium-high heat for 15 minutes. You can also choose to grill them completely on the grill at a very low temperature for several hours. <br />

183.	Use indirect heat on chicken pieces with bones. Otherwise, they are more likely to burn. <br />  

184.	Never use water to put out a grill flare up. Immediately put down the barbecue lid and let the lack of oxygen extinguish the fire. <br />  
<br />
<h2>Meal Planning</h2>

185.	Meal planning saves money, reduces waste and lessens the need for last-minute unhealthy drive-thru runs. <br />  

186.	If you grocery shop weekly, pick a day to plan your meals each week. Write down your plan for breakfast, lunch and dinner. <br />  

187.	From your meal plan, write out your grocery list. Make sure to check your fridge and pantry, so you don’t buy items you already have. <br />  

188.	When making your meal plan, think about how ingredients and leftovers from one meal can be used in the next. For example, if you roast a chicken, you can make soup stock from the carcass and chicken salad from the leftover meat. <br />
<br />
<h2>Meat</h2>
<div className='tips-image'>
    <img src='/img/meat.png' />
</div>
189.	Slicing meat thinly is easier when meat has cooled. If you plan to reuse a pot roast for beef dip the next day, don’t slice it all and make your beef dip slices once it has cooled. <br />

190.	Keep bacon from curling when frying by dipping it into cold water first. <br />  

191.	Place your cooked bacon on a paper towel to soak up excessive grease. You’ll get crispier bacon and just a little less cholesterol. <br />  

192.	Never put bacon grease down your drain. The grease will harden and clog things up. Instead, fill an empty jar or can and discard it when it’s full. Or wait until the grease hardens in your pan and wipe it up with paper towel. <br />

193.	Bring your steaks and beef roasts to room temperature before cooking. It will produce a much more tender meat. <br />  

194.	Let cooked meat rest for before eating. The meat still cooks during this time and the moisture will be better distributed. Wait 5 minutes for small pieces and up to 30 minutes for large roasted meats. <br />

195.	Red meat is a good source of high quality protein, zinc and iron. <br />  

196.	When making gravies and thickening sauces, mix your flour or cornstarch with water first. It will help prevent lumps. <br />  

197.	Kids won’t eat vegetables, but love meat loaf? Just about any vegetable can be pureed and added to your ground beef mixture. <br />  
<br />
<h2>Poultry</h2>
<div className='tips-image r'>
    <img src='/img/poultry.png' />
</div>
198.	Looking for the moistest roast poultry possible? Try brining whole chicken, turkey and other poultry in a salt and water mixture for several hours.<br />

199.	The tastiest brines include extra flavorings from vegetables, citrus fruit and seasoning. Bring the water and salt mixture to a boil with your selected additions and remove from heat as soon as it boils. Cool completely before inserting your poultry.<br />

200.	Beer can chicken produces a very moist and delicious bird. Coat the bird with your favorite rub and carefully place it over a beer can, ¾ full of bear. Roast for approximately 90 minutes at 375 F or to an internal temperature of 165 F. <br />

201.	Add margarine or butter inside the skin of a roasted chicken for a juicier bird and crispier skin. <br />  

202.	Did you know that chicken has about the same amount of cholesterol per serving as beef?  <br />

203.	Poultry is a great source of B vitamins, but doesn’t have as much iron as red meat. <br />  
<br />
<h2>Repurposing Leftovers </h2>

204.	Leftover Roast Chicken: soup stock, chicken salad, chicken potpie, chicken teriyaki.<br />
  
205.	Leftover Pork Roast: pulled pork, Chinese pork buns, fried rice, pork and vegetable soup. <br />  

206.	Leftover Beef Roast: beef dip, a green salad with beef and blue cheese, stir fry, beef barley soup, beef stew. <br />  

207.	Leftover Boiled Potatoes: roast them, mash them, fry them or use them in a shepherd’s pie. <br />  

208.	Leftover Steamed Rice: stir fry, Mexican rice, add it to soup, use it as a wrap sandwich filling. <br />  

209.	Leftover Spaghetti Sauce: chili, add it to nachos, lasagna, shepherd’s pie. <br />  

210.	Leftover Baked Ham: add it to a pasta dish, sandwiches, split pea and ham soup, omelets, quiche and pizza. <br />  

211.	Extra Bread? Make French toast, bread pudding, croutons, stuffing or breadcrumbs. <br />  

212.	Turkey Dinner Leftovers: turkey sandwiches with dinner rolls, french toast dipped with pumpkin pie filling, turkey stock, turkey potpie. <br />  

213.	Leftover Ground Beef: shepherd’s pie, tacos, taco salad, stuffed peppers or add to macaroni and cheese. <br />  

214.	Leftover Vegetables: pizza, soup, quiche, omelet, stir fry, roasted vegetable sandwich. <br />  

215.	Bananas Going Brown? Turn them into banana bread, muffins or pancakes. <br />
 
216.	Overripe Fruit? Thrown them in a smoothie, milk shake or homemade ice cream. <br />  

217.	Small chunks of leftover meat? It doesn’t matter what it is, it can be great in a quesadilla, stir fry or even soup. <br />  
<br />
<h2>Saving Money</h2>

218.	Check for coupons and check your grocery stores websites for weekly specials before you plan your meals and make your grocery list. <br />  

219.	Fresh vegetables always pack the most nutrition, but if you’re stocking up, frozen vegetables are much cheaper than canned. <br />  

220.	Always look in your fridge to see how any leftover vegetables or meat can be incorporated into the next meal. <br />  

221.	Slow cooking meats makes them very tender and soft, making it easier to choose low cost meats that taste just as good. <br />  

222.	Having a designated grocery shopping day each week makes it easier to keep your budget under control. <br />  

223.	Controlling portion sizes is not only good for you, it’s easy on the budget as well. Encourage family members to eat reasonably, use smaller plates and pre-portion treats and snacks. <br />

224.	Make your own oatmeal with cooked oats. You can top with brown sugar, honey, fruit and more for a tasty treat. Pre-packaged oatmeals are expensive and full of sugar. <br />

225.	When buying fresh fruits and vegetables, choose items that are in season. You will always pay a premium for out of season items. <br />  

226.	Plan meals with common ingredients to save money. For example, if you’re using cheese in one meal, include it in another. Or if you’re using a certain vegetable, incorporate it into another meal too.<br />

227.	Shop the perimeter of the grocery store: produce, butcher, dairy and bakery. It’s healthier and it will save you money. <br />  
<br />
<h2>Saving Time</h2>

228.	Can’t open a jar? Put on some rubber kitchen gloves for extra grip. <br />  

229.	Keep kitchen scissors handy. They’re perfect for pre-cutting ribs and pieces of chicken. They also work well on cutting many greens. <br />  

230.	A pizza cutter is perfect for cutting herbs and other light greens. <br />  

231.	Keep frozen fruit on hand for a quick smoothie. <br />  

232.	Keep an inventory of your pantry, fridge and freezer stock, so you know what you have at all times. <br />  

233.	Buy unpackaged bulk foods, where possible. These are often the cheapest. <br />

234.	When purchasing spices, don’t buy a new bottle each time. Buy the spices more cheaply in bags and fill up your old bottles instead. <br />  

235.	A bread maker can save you time and money making bread. If you don’t like the density and texture of bread maker bread, you can still use the machine’s dough setting to prepare your dough and save plenty of time. <br />

236.	Save money by growing your own herb garden. If you’re low on garden space, you can even do it in your kitchen. <br />  

237.	Generic brand foods can save a ton of money. Of course, they don’t always taste as good as their commercial counterparts. Test out new generic items regularly to see which you like and then put those on your regular grocery list. <br />

238.	Make a plan to repurpose meals. If you’re making roast beef one day, plan to make beef dip, soup and more in the following days. <br />  

239.	Good cookware usually lasts longer and is a better investment than cheap ones. You won’t need to use as much oil on cleaning a quality cooking surface. Clean up is also usually easier, resulting in less need for detergents, further saving you money.<br />

240.	Precut vegetables, fruits and cheeses that you use frequently. Not only does it save you time, it encourages healthy eating. <br />  

241.	Reduce your next day dinner preparation by prepping all your ingredients the night before. Cut meats, vegetables and pre-measure any ingredients. <br />

242.	A crockpot helps you save time because it does the cooking for you…all you do is prepare the ingredients. <br />  

243.	Busy tomorrow? Make your meal now and reheat it tomorrow.<br />
  
244.	If you don’t have time to cook a meal on Tuesday, load up your crockpot dish the night before and place it in the refrigerator. Then on Tuesday morning, you can place the dish in the crockpot and turn it on before you go to work. When you get back, dinner will be smelling delicious and ready for you. <br />

245.	One of the best timsavers in the kitchen is to get other family members to help. Whether it’s chopping vegetables, setting the table, doing the dishes or even preparing meals, you don’t have to do it all yourself. <br />

246.	Pre-packaged meals can definitely save you plenty of time, but they can be expensive. Stock up on your favorites when they’re on sale.<br />

247.	If every minute counts, you can purchase pre-minced garlic, onions and other essential ingredients. <br />

248.	A well-organized kitchen is a great timesaver. Keep frequently used items easily accessible and store like items together. <br />  

249.	Keep an easily accessible grocery list on your fridge. Make sure other family members update it as items run low. <br />  

250.	Stock up on items you use frequently. If you seem to make extra trips to the store for milk or eggs, stock up on those items on your regular grocery trip.<br />

251.	Have a designated cooking day each week where you prepare several meals or meal components at once. <br />  

252.	Cook in batches and freeze extra meals for later. <br />  

253.	Invest in a food chopper or food processor, to quickly chop foods. A hand-held food chopper usually has less clean up than a full-sized food processor.<br />

254.	Save time by creating easy “make-ahead” dishes. For example, lasagna or shepherd’s pie that fits in one dish can be pre-prepped and then just placed in the oven for baking when ready. <br />

255.	You don’t need to precook your lasagna noodles. If you have plenty of sauce in your lasagna, the noodles will cook during the baking process. <br />

256.	Clean as you cook and your job will go much faster from cooking to eating to clean up. <br />  

257.	Baking for lunch bag snacks? Pre-portion cookies, muffins and more, so they’re ready to go when it’s time to add them to a lunch. <br />

258.	You can buy ready-to-pour soup stocks to save time. Or for less sodium and better flavor, make a large batch of your own soup stock. Then portion it into small portions (even ice cube trays for very small needs), so it’s ready when you are. <br />

259.	Trying to save time by pre-made cake and other baking mixes? Make them special by adding your own special touch like fresh blueberries, nuts or other favorite ingredient. <br />
<br />
<h2>Scaling Recipes</h2>

260.	To scale a recipe, calculate your “conversion factor”. This is the number of servings you want divided by the number of servings in the recipe. Once you have that number, you can multiply the ingredients in the recipe by that number.<br />

261.	Be careful when scaling a recipe, as some ingredients won’t come out to an easily measurable number. This is where your judgment comes in. When it comes to seasoning and flavorings, adjusting a recipe is easy. For an uneven number of eggs, you can always break an egg, beat it and estimate the portion you need. For important ingredients like baking soda or baking powder, try to be as precise as possible.<br />

262.	When scaling a recipe, be prepared to adjust cooking times where necessary. <br />  

263.	If scaling a recipe doesn’t really work and the portion is too large, just package and freeze the rest for a ready-made meal later. <br />  

264.	Avoid scaling most recipes multiple times over as it’s difficult to maintain the same consistency of the original recipe. Instead, cook in batches to ensure the best result possible. <br />
<br />
<h2>Seasonings, Flavorings, Herbs and Spices</h2>
<div className='tips-image'>
    <img src='/img/herbs.png' />
</div>
265.	To make garlic cloves easier to peel, microwave them for about 10-15 seconds. <br />  

266.	Never cook pesto. Its consistency will be unpalatable. Instead, add pesto just before serving a dish. <br />  

267.	If you have some leftover wine, freeze it into ice cube trays. You can use them for flavoring in sauces anytime. <br />  

268.	Fresh herbs always going bad? They can be combined with water and frozen in ice cube trays for later use. <br />  

269.	If you’re new to using herbs in your cooking, start by observing how they’re used with certain meats and vegetables in recipes. Then start experimenting on your own.<br />

270.	Not sure which herbs or spices go with what? If you’ve purchased your herbs and spices the packaging often has recommended uses.<br />

271.	Table salt is preferred for baking as it dissolves quickly. When it comes to savory meals, sea salt or coarse Kosher salt add texture and a more distinct flavor. <br />

272.	Basil adds minty flavor to foods and fresh basil also has a similar flavoring to cloves. Use it in pesto, with tomatoes, cheese, fish and pasta. <br />

273.	Cilantro is an acquired taste for many, but adds wonderful flavor to salsa, Pad Thai, curries and more. <br />  

274.	Marjoram is similar to oregano, but a bit milder and sweeter. Use it in meats, tomato sauces and more. <br />  

275.	Oregano is a staple for Italian foods. On its own, it has a very strong taste, but is a perfect compliment to soups, stews, fish, pork and vegetables. <br />

276.	Rosemary is used with a wide variety of meats and poultry. It smells and tastes quite like pine. <br />  

277.	Thyme has a lemony and slightly spicy taste. It’s used often with chicken, soups, stews and even plain rice. <br />  

278.	Tarragon has a flavor similar to licorice and can be added to a wide variety of foods including chicken, fish, soups and butter. <br />  

279.	Many herbs and spices have health benefits, so use a variety of them in your recipes daily. <br />  

280.	Try infusing oils with herbs and spices. The extra flavoring means you’ll have to use less fat in your cooking to achieve desirable results. <br />

281.	Garlic is known to keep cancer cells from forming, can help prevent heart disease, hypertension and even has antibacterial properties. <br />  

282.	Caraway seeds are used often in European and Asian cooking. They add texture and flavor to bread, sauerkraut and pork. <br />  

283.	Cayenne pepper can add a little fire to recipes. Use less, taste and then add more if you’d like. <br />  

284.	Ginger is used (usually sparingly) to flavor sweet and savory dishes. Try it with meat, poultry, fish, curries and vegetables. <br />  

285.	Curry powders come in a variety of blends. Experiment to find the ones you like best. <br />  

286.	Paprika is a very versatile spice that can be used in rubs, stews and other meat and poultry dishes. <br />  
<br />
<h2>Soups & Stews</h2>
<div className='tips-image r'>
    <img src='/img/soup.png' />
</div>
287.	If you’re using carrots and celery for your soup stock, just chop them up roughly and throw them in. No need to peel or cut leaves off as you’re just using them for flavor. <br />

288.	Reduce the fat content of your homemade stock by allowing it to cool slightly and skimming the fat. <br />  

289.	Stews often taste better the next day after the flavors have time to blend. <br />  

290.	Add vegetables to soup, according to required cooking time. For example, add carrots and onions earlier than zucchini or small pieces of cauliflower. <br />

291.	Cream soups don’t have to be loaded with fattening cream. Try vegetables like leeks, squash or other vegetables and sauté over oil and onions. Puree when fully cooked and add just a little bit of cream before serving. <br />

292.	Try topping soups with cheese, croutons, green onions or other flavorings. <br />  

293.	If you’re adding pasta to a soup, add it near the end or you will risk overcooking it. <br />  

294.	Collagen in soup stock can give it a gelatinous texture when cooled. This isn’t a bad thing and is an indicator of a very rich stock. <br />  
<br />
<h2>Slow Cooker</h2>

295.	You can usually load up your slow cooker the night before. Just wake up and place the dish in your slow cooker and you’re ready to go. <br />  

296.	Slow cookers produce a lot of liquid, so if you’re using a recipe not meant for a slow cooker, you may want to adjust your liquids. <br />  

297.	Double your slow cooker recipe and freeze half for later. <br />  

298.	Some say to brown meat before adding to a slow cooker, but if you like browned meat, do some browning AFTER the cooking process. <br />  

299.	Resist temptation to stir ingredients. Lifting the lid will interrupt the vacuum created and allow steam to escape. <br />  

300.	Browning meats before adding them to a crockpot can improve flavor, but if you’re in a hurry, it’s rarely necessary. <br />  

301.	Don’t try to cook dry beans with other slow cooker ingredients. They need to be soaked first. <br />  

302.	If you’re using frozen vegetables in a slow cooker, bring them to room temperature and add them in the last 30-60 minutes since they are already cooked. <br />
<br />
<h2>Storage</h2>

303.	Store olive oil in a dark, room temperature cupboard as heat, light and air can affect its taste. <br />  

304.	Store potatoes in a cool, dark place. Prolonged exposure to light can cause your potatoes to turn green and taste bitter. <br />  

305.	To store onions for a few months, hang them in a mesh bag in a cool, dry place. <br />  

306.	Store brown sugar in an airtight container to keep it from hardening. <br />  

307.	Always make sure celery is wrapped with plastic or foil when in the fridge. Otherwise, it will become rubbery. <br />  

308.	To avoid mold, store cheese in an airtight container or bag in the freezer. <br />  

309.	When storing lettuce, add some folded paper towel to the bottom and top of your container to absorb excess water. <br />  

310.	Store milk in the container provided and in the refrigerator. <br />  

311.	When in doubt about how to store a particular food and for how long, read the label. <br />  

312.	Harder cheeses last longer than soft ones, but either way, make sure they are tightly wrapped and stored in your fridge. <br />  

313.	Store fresh herbs by washing them and drying them. Then wrap them in paper towel and place in an airtight container or bag. <br />  

314.	Never freeze cheese. It changes the flavor and ruins the texture. <br />  

315.	Store your canned goods in a cool, dry place. They can generally be stored for about 12 months. <br />  

316.	Store bananas at room temperature. They will brown in the fridge. <br />  

317.	Keep the stems on your cherries when storing. They’ll keep longer. <br />  

318.	Hard honey hasn’t gone bad. Crystallization is normal with honey and you can soften it by microwaving it. <br />  

319.	Freshly sliced deli meats stay fresh for only about 3-5 days. Store them in an airtight container and discard if they show any signs of spoilage. <br />
<br />
<h2>Substitutions</h2>

320.	Out of breadcrumbs? You can often substitute them for cracker crumbs, corn flake crumbs or ground oats. <br />  

321.	Beer or white wine in a recipe can be substituted with equal parts chicken broth. <br />  

322.	If you don’t have unsalted butter, you can try shortening, vegetable or olive oil. <br />  

323.	¼ cup cocoa is equal to approximately 1 – 1 ounce square of unsweetened chocolate. <br />  

324.	1 bay leaf equals ¼ teaspoon crushed. <br />  

325.	1 cup half and half cream is easily substituted with 1 tablespoon melted butter and milk to equal 1 cup.   <br />

326.	1 cup heavy cream can be replaced by 1 cup evaporated milk OR ¾ cup milk with 1/3 cup of butter. <br />  

327.	Next time you bake reduce the cholesterol by substituting 1 egg with ½ a mashed banana and ½ teaspoon baking powder. <br />  

328.	Out of ketchup, but need 1 cup of it to cook? Replace is with 1 cup tomato sauce, 1 tablespoon sugar and 1 teaspoon vinegar. <br />  

329.	Can’t make lemon zest because you don’t have fresh lemons? Replace it with equal parts of lemon juice. <br />  

330.	Chives are expensive. You can replace them in a recipe with green onions. <br />  

331.	Don’t want to put mayonnaise in your recipe? Try 1 cup of sour cream OR 1 cup plain yogurt OR 1 cup pureed cottage cheese. <br />  

332.	1 tablespoon of cornstarch can be replaced by 2 tablespoons of flour. <br />  

333.	Instead of non-fat milk, you can use non-fat powdered milk. It’s more economical too! Just replace 1 cup skim milk with 5 tablespoons of powder and add enough water to make 1 cup. <br />

334.	1 cup of mini marshmallows is roughly equal to 8-10 full-sized marshmallows. <br />  

335.	Out of brown sugar? You can substitute 1 cup for 1 cup white sugar and ¼ cup molasses. Just reduce the other liquids in the recipe by about ¼ cup. <br />

336.	The juice of one lemon is equal to about ¼ cup of juice. <br />  

337.	1 clove of garlic equals 1/8 of a teaspoon of garlic powder or ½ teaspoon garlic salt. <br />  

338.	1 cup honey can be substituted with 1 cup corn syrup, but honey is probably better for your health. <br />  

339.	No buttermilk? Substitute with regular milk and add 1 tablespoon of lemon juice per cup of milk. Let stand for about 15 minutes. <br />  

340.	Have a square pan, but need a round one? An 8-inch square pan can be substituted for a 9-inch round one.   <br />

341.	1 teaspoon of baking powder can be replaced with ¼ teaspoon of baking powder ½ teaspoon of cream of tartar. <br />  

342.	1 teaspoon of mustard powder can be replaced with 1 tablespoon of prepared mustard. <br />  

343.	1 tablespoon of fresh parsley equals 1 teaspoon dried parsley. <br />  

344.	When baking, you can often substitute vegetable oil for equal parts applesauce. <br />  

345.	Make approximately 1 teaspoon of your own pumpkin pie spice by combining ½ teaspoon cinnamon, ¼ teaspoon ground ginger, ¼ teaspoon ground cloves and a pinch of ground nutmeg. <br />

346.	Don’t have raisins? Replace 1 cup with 1 cup of dried cranberries or currants. <br />  
<br />
<h2>Vegetables</h2>
<div className='tips-image'>
    <img src='/img/veg.png' />
</div>
347.	Boiling vegetables causes more vitamins and nutrients to be lost. Steam them instead. <br />  

348.	Russet potatoes make great baked potatoes. They’re starchy and their skins taste great when baked. <br />  

349.	Remove the husks and soak corn on the cob for about 1 hour before grilling. It will retain its moisture. <br />  

350.	Soaking potatoes in water for 15 minutes before baking will result in a crispier skin. <br />  

351.	If cutting onions makes your eyes water, try soaking the onions in water for about 15 minutes. <br />  

352.	Using stewed tomatoes and need them sliced up? Open the can and take a sharp knife and swipe the knife across several times. <br />  

353.	Pre-making a green salad? You can cut the tomatoes ahead of time, but store them separately to prevent the salad from going soggy and reducing its freshness.<br />

354.	Take care to cut vegetables into even pieces so they’ll cook evenly. <br />  

355.	To steam vegetables, place a colander or steamer on top of a pot filled with water. Make sure the water doesn’t touch your colander or steamer. Bring water to boil and add vegetables and top with a lid. Steam to desired doneness.<br />

356.	To sauté vegetables means to cook to them in a pan with a small amount of oil on relatively high heat. If you’re cooking very dense vegetables like Brussels sprouts or carrots, you may want to steam them lightly first or they may not be as tender as you’d like. <br />

357.	When microwaving vegetables, add a little water to your microwave safe bowl. You can also steam vegetables in a microwave with a microwave steamer or if you don’t have one, a microwave safe bowl topped with a microwave safe colander and lid. <br />

358.	Vegetables don’t really need butter. Use herbs, spices and a squeeze of lemon juice for a more healthy approach. <br />  

359.	Tip for roasting vegetables: Cut in large chunks as smaller pieces will tend to burn and lose the great texture roasting can provide. <br />  

360.	Have you ever replaced mashed potatoes with mashed cauliflower? It’s delicious and has fewer carbohydrates and plenty of nutritional benefits. <br />

361.	Fresh spinach is a bit labor intensive to wash, but tastes great. To make the process as easy as possible, submerge the leaves in water for a few seconds instead of trying to clean them with running tap water. <br />

362.	Did you know that mushrooms shouldn’t be soaked in water? You can use a little bit of water and immediately brush off any loose dirt, but never soak them. <br />

363.	Fresh vegetables taste better, but having a stock of frozen vegetables ensures you’ve got a steady supply and never run out. <br />  

364.	Onions have plenty of fiber, vitamin C and may help prevent the growth of tumors. Plus, they’re a great kitchen staple, so stock up. Use them with sauté or roasted vegetables or meats, in soups and more. <br />

365.	If you’re using yellow or red potatoes to make mash, there’s no need to peel them first. The skins are flavorful, have a nice texture and have plenty of fiber. <br />

366.	Peel and cut your vegetables ahead of time, so you can focus on cooking. <br />

                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default QuickTips;